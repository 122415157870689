<template>
  <div class="sidebar">
    <div
      v-if="showPanel"
      class="sidebar-backdrop"
      @click.self="closeModal"
    ></div>

    <transition name="slide">
      <div
        v-if="showPanel"
        class="sidebar-panel"
        @keydown="checkKeyEvent($event, $el)"
        @keydown.esc="closeModal"
      >
        <div class="sidebar-panel__close">
          <a href="#" @click.prevent="closeModal">
            <i class="fas fa-times sidebar-panel__close-icon"></i>
          </a>
        </div>

        <div class="flex-box">
          <div class="flex-box__left-column">
            <h2 class="sidebar-panel__heading">Metering</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/smart_meter/manage_bookings">
                  Smart Meter Booking Portal
                </router-link>
              </li>
              <li>
                <router-link to="/smart_meter/uncommissioned_tickets">
                  Create Uncommissioned Tickets
                </router-link>
              </li>
              <li>
                <router-link to="/smart_meter/meter-readings">
                  Smart Meter - Readings
                </router-link>
              </li>
            </ul>

            <h2 class="sidebar-panel__heading">Warm Home Discount</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/warm_home_discount/applications">
                  Manage Applications
                </router-link>
              </li>
            </ul>

            <h2 class="sidebar-panel__heading">Operations</h2>

            <h3 class="sidebar-panel__subheading">Registrations</h3>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/rejections/industry">
                  Industry rejections
                </router-link>
              </li>
              <li>
                <router-link to="/rejections/junifer">
                  Junifer rejections
                </router-link>
              </li>
              <li>
                <router-link to="/rejections/junifer/resubmit">
                  Resubmit registrations
                </router-link>
              </li>
            </ul>

            <h3 class="sidebar-panel__subheading">Opening Readings</h3>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/new_opening_reads/rejections">
                  Rejected opening reads
                </router-link>
              </li>
              <li>
                <router-link to="/opening_reads/rejections/dispute">
                  Disputed readings
                </router-link>
              </li>
              <li>
                <router-link to="/opening_reads/validated/account">
                  Validated readings
                </router-link>
              </li>
            </ul>

            <h3 class="sidebar-panel__subheading">Account Losses</h3>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/accounts/missing_loss_confirmation">
                  Late Loss Dataflows
                </router-link>
              </li>
            </ul>

            <h3 class="sidebar-panel__subheading">Junifer Tools</h3>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/tools/update_ticket_step_bulk">
                  Update Ticket Steps in Bulk
                </router-link>
              </li>
            </ul>
          </div>

          <div class="flex-box__right-column">
            <h2 class="sidebar-panel__heading">Payment Adequacy</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/payments/adequacy/adhoc"> Adhoc </router-link>
              </li>
              <li>
                <router-link to="/payments/adequacy/bulk-uploader">
                  Payment Adequacy Bulk Uploader
                </router-link>
              </li>
              <li>
                <router-link to="/payments/refund/standalone">
                  Standalone refunds
                </router-link>
              </li>
            </ul>
            <h3 class="sidebar-panel__subheading">6 month adequacy</h3>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/payments/adequacy/pre_review">
                  Pre review email
                </router-link>
              </li>
              <li>
                <router-link to="/payments/adequacy/initial">
                  Initial review
                </router-link>
              </li>
              <li>
                <router-link to="/payments/adequacy/follow_up">
                  Follow up review
                </router-link>
              </li>
            </ul>

            <h2 class="sidebar-panel__heading">Communications</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/communications/summary">
                  Summary
                </router-link>
              </li>
              <li>
                <router-link to="/communications/account_lookup">
                  Account Lookup
                </router-link>
              </li>
              <li>
                <router-link to="/communications/blacklist">
                  Blacklist
                </router-link>
              </li>
              <li>
                <router-link to="/communications/templates">
                  Templates
                </router-link>
              </li>
            </ul>

            <h2 class="sidebar-panel__heading">Ad Hoc Email Tool</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/email_tool/send_email">
                  Send Email
                </router-link>
              </li>
            </ul>

            <h2 class="sidebar-panel__heading">Import New Tariffs</h2>
            <ul class="sidebar-panel__list">
              <li>
                <router-link to="/import_new_tariffs">
                  Import New Fixed Tariffs
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="sidebar-panel__user-guides">
          <b-link
            class="
              button button--blue-outlined
              sidebar-panel__user-guides-button
            "
            @click="signOut()"
          >
            Sign Out
          </b-link>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { trapFocus } from "../../mixins/trapFocus"
import authentication from "../../authentication"
export default {
  name: "Sidebar",
  mixins: [trapFocus],
  props: {
    showPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isAuthenticated() {
      return authentication.isAuthenticated()
    },
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    signOut() {
      authentication.signOut()
    },
  },
}
</script>

<style scoped lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.4s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 0.4s ease-in 0s;
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 4;
}

.sidebar-panel {
  overflow-y: auto;
  background-color: $day;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 999;
  width: 600px;
  text-align: left;

  &__close {
    text-align: right;
    margin: $spacing-5 $spacing-7 0 0;
    font-size: $size-6;
  }

  &__heading {
    font-weight: $weight-bold;
    font-size: $size-6;
  }

  &__subheading {
    font-size: $size-7;
    font-weight: $weight-bold;
    margin-left: $spacing-2;
  }
  &__list {
    font-weight: $weight-bold;
    list-style: none;
    margin-bottom: $spacing-4;

    li {
      line-height: 2.4;
      margin-bottom: $spacing-1;
      margin: 0px;

      a {
        padding: $spacing-2 $spacing-4 $spacing-2;

        &:before {
          font-family: "Font Awesome 5 Free";
          font-weight: $weight-bold;
          content: "\f0da";
          padding: 0 $spacing-2 0 0;
          vertical-align: middle;
        }
      }

      &:hover {
        a {
          text-decoration: none;
          border-radius: 20px;
          color: $water;
          background: $blue-50;

          &:before {
            color: $water;
          }
        }
      }
    }
  }

  &__user-guides {
    background: $blue-50;
    padding: $spacing-6;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  &__user-guides-button {
    background: $blue-50;
  }
}

.flex-box {
  display: flex;
  padding: $spacing-6;

  &__left-column {
    flex: 0 0 50%;
    padding-top: $spacing-5;
  }

  &__right-column {
    flex: 0 0 50%;
    padding: 1.25rem 0 0 $spacing-5;
    border-left: 1px solid $night-light-2;
  }
}
</style>
