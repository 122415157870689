<template>
  <div class="home">
    <div class="home__top-bg-container">
      <section class="home__inner">
        <section class="home__header">
          <h2>Hello {{ getUserProfile.given_name }}</h2>
        </section>
      </section>
    </div>

    <div class="home__bg-container">
      <section class="home__inner">
        <section class="home__body">
          <div class="home__left-column">
            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Operations</h2>
              </template>
              <template slot="body">
                <h3 class="home__card-body-heading">Registrations</h3>
                <p class="home__card-subheader">
                  Notify customers that meterpoint(s) have been rejected. Users
                  can also edit rejected registration attempts and resubmit.
                </p>
                <ul class="home__card-list">
                  <li>
                    <router-link to="/rejections/industry">
                      Industry rejections
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/rejections/junifer">
                      Junifer rejections
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/rejections/junifer/resubmit">
                      Resubmit registrations
                    </router-link>
                  </li>
                </ul>

                <h3 class="home__card-body-heading">Opening Readings</h3>
                <p class="home__card-subheader">
                  Notify customers that their reading has failed
                  validation/previous supplier has their opening readings/raise
                  a dispute within Junifer.
                </p>
                <ul class="home__card-list">
                  <li>
                    <router-link to="/new_opening_reads/rejections">
                      Rejected opening reads
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/opening_reads/rejections/dispute">
                      Disputed readings
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/opening_reads/validated/account">
                      Validated readings
                    </router-link>
                  </li>
                </ul>

                <h3 class="home__card-body-heading">Account Losses</h3>
                <p class="home__card-subheader">
                  Automated account lifecycle management.
                </p>
                <ul class="home__card-list">
                  <li>
                    <router-link to="/accounts/missing_loss_confirmation">
                      Late Loss Confirmation Dataflows
                    </router-link>
                  </li>
                </ul>

                <h3 class="home__card-body-heading">Junifer Tools</h3>
                <p class="home__card-subheader">
                  Tools to automate basic Junifer functions
                </p>
                <ul class="home__card-list">
                  <li>
                    <router-link to="/tools/update_ticket_step_bulk">
                      Update Ticket Steps in Bulk
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-operations"></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Communications</h2>
                <p class="home__card-subheader">
                  Summary of all communications sent by ARK.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/communications/summary">
                      Summary
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/communications/account_lookup">
                      Account Lookup
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/communications/blacklist">
                      Blacklist
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/communications/templates">
                      Templates
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div
                  class="home__card-icon home__card-icon-communications"
                ></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Ad Hoc Email Tool</h2>
                <p class="home__card-subheader">
                  Tool for Product team. Upload and email customers an ad hoc
                  email using a pre-defined template.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/email_tool/send_email">
                      Send Email
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div
                  class="home__card-icon home__card-icon-communications"
                ></div>
              </template>
            </IconCard>
          </div>

          <div class="home__right-column">
            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Metering</h2>
                <p class="home__card-subheader">
                  Booking meter appointments and managing the smart meter
                  campaign. Notify customers when their new smart meters are
                  un-commissioned.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/smart_meter/manage_bookings">
                      Smart Meter Booking Portal
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/smart_meter/uncommissioned_tickets">
                      Un-commissioned Smart Meter Tickets
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/smart_meter/meter-readings">
                      Smart Meter - Readings
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-smart"></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Warm Home Discount</h2>
                <p class="home__card-subheader">
                  Notify customers that their application has been successful,
                  requires verification or failed audit.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/warm_home_discount/applications">
                      Manage Applications
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-warm-home"></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Payment Adequacy</h2>
                <p class="home__card-subheader">
                  We review our customers payment amount 6 months after their
                  payment schedule has been created.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/payments/adequacy/adhoc">
                      Adhoc
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/payments/adequacy/bulk-uploader">
                      Payment Adequacy Bulk Uploader
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/payments/refund/standalone">
                      Standalone refunds
                    </router-link>
                  </li>
                </ul>

                <h3 class="home__card-body-heading">6 month adequacy</h3>
                <ul class="home__card-list">
                  <li>
                    <router-link to="/payments/adequacy/pre_review">
                      Pre review email
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/payments/adequacy/initial">
                      Initial review
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/payments/adequacy/follow_up">
                      Follow up review
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-adequacy"></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Import New Tariffs</h2>
                <p class="home__card-subheader">
                  Imports new tariffs to Ark and Spark when tariff updates are
                  made by Trading.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/import_new_tariffs">
                      Import New Fixed Tariffs
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-adequacy"></div>
              </template>
            </IconCard>

            <IconCard>
              <template slot="header">
                <h2 class="home__card-header">Account Finder</h2>
                <p class="home__card-subheader">
                  Generate a list of accounts which satisfy specific
                  requirements.
                </p>
              </template>
              <template slot="body">
                <ul class="home__card-list">
                  <li>
                    <router-link to="/account_finder">
                      Account Finder
                    </router-link>
                  </li>
                </ul>
              </template>
              <template slot="icon">
                <div class="home__card-icon home__card-icon-operations"></div>
              </template>
            </IconCard>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import authentication from "../authentication"
import IconCard from "@/components/IconCard"
export default {
  name: "Home",
  components: {
    IconCard,
  },
  computed: {
    getUserProfile() {
      return authentication.getUserProfile()
    },
  },
}
</script>

<style lang="scss">
.home {
  padding: 0 0 $spacing-8 0;
  min-height: 100vh;
  background-color: $day-dark;

  &__top-bg-container {
    background-color: $night;
  }

  &__bg-container {
    margin-top: $spacing-8;
    position: relative;
  }

  &__inner {
    min-width: 1024px;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }

  &__header {
    text-align: left;
    padding: $spacing-6 0 $spacing-5 0;
    position: relative;
    color: $day;

    h2 {
      font-size: $size-1;
      font-weight: $weight-bold;
      margin-bottom: 0;
    }

    h2 {
      font-size: $size-3;
      font-weight: $weight-bold;
      margin-bottom: 0;
    }

    .button {
      position: absolute;
      top: 82px;
      right: 0;
      font-weight: $weight-bold;
      background: $blue-50;

      .far {
        font-size: $size-7;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__body {
    display: flex;
  }

  &__left-column {
    flex: 0 0 50%;
  }

  &__right-column {
    flex: 0 0 50%;
    padding: 0 0 0 $spacing-6;
  }

  &__card-header {
    font-weight: $weight-bold;
    font-size: $size-6;

    @include lg {
      font-size: $size-5;
    }
  }

  &__card-subheader {
    margin-bottom: $spacing-2;
  }

  &__card-body-heading {
    font-size: $size-7;
    font-weight: $weight-bold;
    margin-top: $spacing-5;
  }

  &__card-list {
    font-weight: $weight-medium;
    list-style: none;

    li {
      line-height: 2;
      margin-bottom: $spacing-1;
      margin: 0px;

      a {
        padding: $spacing-2 $spacing-4 $spacing-2 $spacing-2;
        color: $water-dark;
      }

      &:hover {
        a {
          text-decoration: underline;
          border-radius: 20px;
        }
      }
    }
  }

  &__card-icon {
    background-size: cover;
    background-position: center;
    height: 100px;
    width: 100px;
    margin: auto;
    border-radius: 50%;
  }

  &__card-icon-smart {
    background-image: url("../assets/images/Smart-meter-booking.svg");
  }

  &__card-icon-warm-home {
    background-image: url("../assets/images/winter_finance.jpg");
  }

  &__card-icon-operations {
    background-image: url("../assets/images/operations.svg");
  }

  &__card-icon-adequacy {
    background-image: url("../assets/images/energy-saving.jpg");
  }

  &__card-icon-communications {
    background-image: url("../assets/images/communications.png");
  }
}
</style>
